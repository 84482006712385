import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Blogs from './constants/blogs-data';

const Blog = () => {
  const onDiscoverMoreClicked = (url: string) => {
    window.open(url, '_blank')?.focus();
  }
  return (
    <div id="blog" className="blog">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 offset-lg-4  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
            <div className="section-heading">
              <h6>Recent News</h6>
              <h4>Check Our Blog <em>Posts</em></h4>
              <div className="line-dec"></div>
            </div>
          </div>
        </div>
        <Container>
          <Row xs={1} md={2} lg={3}>
            {Blogs.map((blog, index: number) => {
              return (
                <Col key={index}>
                  <Card style={{marginTop: "10px", borderRadius: "15px"}}>
                    <Card.Img variant="top" src={blog.imageUrl} style={{overflow: "hidden", height: "183px", borderTopLeftRadius: "15px", borderTopRightRadius: "15px"}} />
                    <Card.Body>
                      <Row>
                        <Col>
                          <span style={{
                            fontSize: "15px",
                            color: "#fff",
                            padding: "8px 12px",
                            backgroundColor: "#4da6e7",
                            borderRadius: "18px",
                            display: "inline-block"
                          }}>{blog.badge}</span>
                        </Col>
                        <Col
                          style={{
                            textAlign: "end",
                            color: "#afafaf",
                            fontSize: "15px",
                            marginTop: "4px"
                          }}
                          >{blog.date}</Col>
                      </Row>
                      <Card.Title style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: "#2a2a2a",
                        marginTop: "20px",
                        marginBottom: "20px",
                        lineHeight: "30px"
                      }}>
                        {blog.title}
                      </Card.Title>
                      <Card.Text>
                        {blog.description}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer style={{
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                  }}>
                    <Button
                      onClick={() => onDiscoverMoreClicked(blog.referenceUrl)}
                      style={{
                        backgroundColor: "#fff",
                        borderColor: "#4da6e7",
                        borderRadius: "23px",
                        color: "#4da6e7",
                        padding: "0.65rem 2.0rem"
                      }}
                    >
                      Discover More
                    </Button>
                  </Card.Footer>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </div>  
  )
}

export default Blog;