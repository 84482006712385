import emailjs from "@emailjs/browser";
import { useEffect, useState } from 'react';

interface ContactProps {
  onToastShow: (showToast: boolean, success: boolean) => void;
}

const Contact = (props: ContactProps) => {
  const emailAddress = "info@ThinqPr.com";
  
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setName(event.target.value);
  };

  const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setMessage(event.target.value);
  };

  const onSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSubject(event.target.value);
  };

  const onSendMessage = async (e: any) => {
    e.preventDefault();
    const serviceId: string = "service_b0vf8ir";
    const templateId: string = "template_lrhzafa";
    if( email.length && name.length && subject.length && message.length) {
      try {
        await emailjs.send(serviceId, templateId, {
          from_email: email,
          from_name: name,
          message: message,
          subject: subject
        });
        props.onToastShow(true, true);
      } catch (error) {
        props.onToastShow(true, false);
      } finally {
      }
    }
  }

  useEffect(() => emailjs.init("UKd9Azsol2LIqERqI"), []);
    return (
      <div id="contact" className="contact-us section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="section-heading wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
              <h6>Contact Us</h6>
              <h4>Get In Touch With Us <em>Now</em></h4>
              <div className="line-dec"></div>
            </div>
          </div>
          <div className="col-lg-12 wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.25s">
            <form id="contact">
              <div className="row">
                <div className="col-lg-12">
                  <div className="contact-dec">
                    <img src="assets/images/contact-dec-v3.png" alt=""/>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div id="map">
                  <iframe title="unique title" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.439262462826!2d-118.49357872448317!3d34.05825227315465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2a34deb42ffe1%3A0xe01781fac16c3852!2s126%20N%20Rockingham%20Ave%2C%20Los%20Angeles%2C%20CA%2090049%2C%20USA!5e0!3m2!1sen!2sin!4v1706792058865!5m2!1sen!2sin" width="100%" height="636px" style={{border:"0"}} allowFullScreen loading="lazy"></iframe>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="fill-form">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="info-post">
                          <div className="icon">
                            <img src="assets/images/phone-icon.png" alt=""/>
                            <button className="whatsapp-btn" onClick={() => window.open("https://wa.me/16695775608", '_blank')?.focus()}>0669-577-5608</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="info-post">
                          <div className="icon">
                            <img src="assets/images/email-icon.png" alt=""/>
                            <a href={`mailto:${emailAddress}?subject=SendMail&body=Description`}>{emailAddress}</a>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="info-post">
                          <div className="icon">
                            <img src="assets/images/location-icon.png" alt=""/>
                            <button className="whatsapp-btn" onClick={() => window.open("https://maps.app.goo.gl/1tTfi26d4uUMkjfy9", '_blank')?.focus()}>126 Los Angeles CA</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <fieldset>
                          <input value={name} type="name" name="name" id="name" placeholder="Name" autoComplete="on" onChange={onNameChange} required/>
                        </fieldset>
                        <fieldset>
                          <input value={email} onChange={onEmailChange} type="text" name="email" id="email" pattern="[^ @]*@[^ @]*" placeholder="Your Email" required/>
                        </fieldset>
                        <fieldset>
                          <input value={subject} onChange={onSubjectChange} type="subject" name="subject" id="subject" placeholder="Subject" autoComplete="on"/>
                        </fieldset>
                      </div>
                      <div className="col-lg-6">
                        <fieldset>
                          <textarea value={message} onChange={onMessageChange} name="message" className="form-control" id="message" placeholder="Message" required></textarea>  
                        </fieldset>
                      </div>
                      <div className="col-lg-12">
                        <fieldset>
                          <button id="form-submit" className="main-button" onClick={onSendMessage}>Send Message Now</button>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact;
