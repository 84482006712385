import { WhoWeAre } from "./constants/agency-info";


const About = () => {
    return (
      <div id="about" className="about section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-left-image  wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                  <img src="assets/images/about-dec-v3.png" alt=""/>
                </div>
              </div>
              <div className="col-lg-6 align-self-center  wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.5s">
                <div className="about-right-content">
                  <div className="section-heading">
                    <h6>About Us</h6>
                    <h4>
                      Who are we
                      {/* <em>Agency</em> */}
                    </h4>
                  </div>
                  <p>
                    {WhoWeAre}
                    <br/>
                    <span style={{fontWeight: "bold", color: "#4da6e7"}}>Our Services include</span>
                    <ul>
                      <li style={{listStyle: "initial"}}>Organizing podcasts and interviews with topline companies, newspapers, magazines etc.</li>
                      <li style={{listStyle: "initial"}}>Develop timely & meaningful publicity campaigns & submit/add books in celebrity book clubs.</li>
                      <li style={{listStyle: "initial"}}>Secure Earned Media (TV, print, radio, and podcasts) using media pitches to reporters and a Digital Press Kit.</li>
                      <li style={{listStyle: "initial"}}>Ensuring/providing book sales through our established marketing strategies.</li>
                      <li style={{listStyle: "initial"}}>Organizing virtual/physical book tours and booking ads for the billboards at Times Square NYC.</li>
                      <li style={{listStyle: "initial"}}>Posting verified purchase reviews on Amazon, Google, Goodreads etc., along with publishing articles & reviews in magazines and newspapers.</li>
                      <li style={{listStyle: "initial"}}>Digital Media Marketing to increase your Word-of-Mouth Marketing through engaging websites, email marketing, creating hype across social media, custom videos and more.</li>
                    </ul>
                  </p>
                  <p></p>
                  <div className="row">
                    <div className="col-lg-4 col-sm-4">
                      <div className="skill-item first-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                        <div className="progress" data-percentage="90">
                          <span className="progress-left">
                            <span className="progress-bar"></span>
                          </span>
                          <span className="progress-right">
                            <span className="progress-bar"></span>
                          </span>
                          <div className="progress-value">
                            <div>
                              90%<br/>
                              <span>Success Ratio</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <div className="skill-item second-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                        <div className="progress" data-percentage="80">
                          <span className="progress-left">
                            <span className="progress-bar"></span>
                          </span>
                          <span className="progress-right">
                            <span className="progress-bar"></span>
                          </span>
                          <div className="progress-value">
                            <div>
                              80%<br/>
                              <span>Repeated Orders</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <div className="skill-item third-skill-item wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
                        <div className="progress" data-percentage="90">
                          <span className="progress-left">
                            <span className="progress-bar"></span>
                          </span>
                          <span className="progress-right">
                            <span className="progress-bar"></span>
                          </span>
                          <div className="progress-value">
                            <div>
                              90%<br/>
                              <span>Sales Guarantee</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;