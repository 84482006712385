import { useState } from 'react';
import { Packages } from './constants/packages';

const Services = () => {
  const [selectedTab, setSelectedTab] = useState(Packages[0]);
    return (
        <div id="services" className="services section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
            <h6>Our Services</h6>
            <h4>What Our Agency <em>Provides</em></h4>
            <div className="line-dec"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="naccs">
            <div className="grid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="menu">
                    {Packages.map((pack, index) => {
                      let containerClassName = "";
                      if(index === 0) {
                        containerClassName += " first-thumb";
                      }
                      if(pack.id === selectedTab.id) {
                        containerClassName += " active";
                      }
                      if(index === (Packages.length-1)) {
                        containerClassName += " last-thumb";
                      }
                      return (
                        <div className={containerClassName} style={{margin: "2px"}} key={index} onClick={() => setSelectedTab(pack)}>
                          <div className="thumb">
                            <span className="icon"><img src={pack.tabImgUrl} alt=""/></span>
                            {pack.name}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div> 
                <div className="col-lg-12">
                  <ul className="nacc">
                    {
                      Packages.map((pack, index) => {
                        return (
                          <li className={`${pack.id === selectedTab.id ? "active" : ""}`} key={index}>
                            <div>
                              <div className="thumb">
                                <div className="row">
                                  <div className="col-lg-6 align-self-center">
                                    <div className="left-text">
                                      <h4>{pack.detail.name}</h4>
                                      <p>{pack.detail.description}</p>
                                      <div className="ticks-list">
                                        {pack.detail.services.map((serviceValue, index) => {
                                          return (
                                            <span key={index}>
                                              <i className="fa fa-check"></i> {serviceValue}
                                            </span>
                                          )}
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 align-self-center">
                                    <div className="right-image">
                                      <img src={pack.detail.descriptionImgUrl} alt=""/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}

export default Services;