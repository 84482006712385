import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { AuthorReviews } from './constants/author-reviews';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Container } from "react-bootstrap";

interface PortfolioProps {
  onReadMoreClick: (review: any) => void
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Portfolio = (props: PortfolioProps) => {
  

  return (
    <Container>
      <div id="portfolio" className="our-portfolio section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-heading wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                <h6>Our Portofolio</h6>
                <h4>See Our <em>Testimonials</em></h4>
                <div className="line-dec"></div>
              </div>
            </div>
          </div>
        </div>
        <Carousel
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          responsive={responsive}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {AuthorReviews.map((review, index) => {
            return (
              <Card style={{margin: "10px", borderRadius: "15px"}} key={index}>
                <Card.Img variant="top" src={review.authorImageUrl} style={{overflow: "hidden", height: "183px", borderTopLeftRadius: "15px", borderTopRightRadius: "15px"}} />
                <Card.Body style={{overflow: "hidden", height: "180px"}}>
                  {review.publisher && (
                    <>
                      <Card.Title>{review.publisher}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        <p style={{borderBottom: "1px solid #eee"}}>
                          Publisher of {" "}
                          <span style={{fontWeight: "bold"}}>{review.book}</span>
                          {" "} by {" "}
                          <span style={{fontWeight: "bold"}}>{review.author}</span>
                        </p>
                      </Card.Subtitle>
                    </>
                  )}
                  {!review.publisher && (
                    <>
                      <Card.Title>{review.author}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        <p style={{borderBottom: "1px solid #eee"}}>
                          Author of {" "}
                          <span style={{fontWeight: "bold"}}>{review.book}</span>
                        </p>
                      </Card.Subtitle>
                    </>
                  )}
                  <Card.Text>
                  {review.shortDescription}
                  </Card.Text>
                </Card.Body>
                <Card.Footer style={{
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}>
                  <Button
                    onClick={() => props.onReadMoreClick(review)}
                    style={{
                      backgroundColor: "#4da6e7",
                      borderColor: "#4da6e7",
                      borderRadius: "20px",
                    }}
                  >
                    Read More
                  </Button>
                </Card.Footer>
              </Card>
            )
          })}
        </Carousel>
      </div>
    </Container>
  )
}

export default Portfolio;