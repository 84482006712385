const Blogs = [
    {
        id: 1,
        imageUrl: "assets/images/crossroads-nyt.jpg",
        badge: "Book Review",
        date: "Sept. 27, 2021",
        title: "Jonathan Franzen’s ‘Crossroads,’ a Mellow, ’70s-Era Heartbreaker That Starts a Trilogy",
        description: "Jonathan Franzen’s new novel, “Crossroads,” is the first in a projected trilogy, which is reason to be wary.",
        referenceUrl: "https://www.nytimes.com/2021/09/27/books/review-jonathan-franzen-crossroads.html"
    },
    {
        id: 2,
        imageUrl: "assets/images/miriam_yt.png",
        badge: "YouTube Interview",
        date: "Sept. 11, 2021",
        title: "Miriam Toews on her new novel Fight Night, her mother and the Mennonite community",
        description: "Bestselling Canadian author Miriam Toews sat down with Tom Power to discuss her highly anticipated ...",
        referenceUrl: "https://www.youtube.com/watch?v=9QXquHw2XrY&ab_channel=QwithTomPower"
    },
    {
        id: 3,
        imageUrl: "assets/images/pomegranate-qa.jpg",
        badge: "Q&A Session",
        date: "June 30, 2023",
        title: "A conversation with Helen Elaine Lee about her novel, “Pomegranate”",
        description: "The MIT professor’s new book explores the world of a woman set free from prison and redefining herself in society.",
        referenceUrl: "https://news.mit.edu/2023/helen-elaine-lee-pomegranate-novel-conversation-0630"
    },
    {
        id: 4,
        imageUrl: "assets/images/samantha_review.jpeg",
        badge: "Interview",
        date: "Sept. 9, 2022",
        title: "Interview with Author Samantha San Miguel",
        description: "Samantha San Miguel grew up barefoot in South Florida. Living in this wild, diverse, and exuberant state taught Samantha a lifelong respect ...",
        referenceUrl: "https://www.geeksout.org/2022/09/09/interview-with-author-samantha-san-miguel/"
    },
    {
        id: 5,
        imageUrl: "assets/images/Loyalty_opinion.png",
        badge: "Opinion",
        date: "June 29, 2023",
        title: "Lisa Scottoline's historical thriller introduces Sicily's first Mafia family",
        description: "I was drawn to “Loyalty,” Lisa Scottoline’s historical novel about Sicily, because I’ve enjoyed her novels in the past, and because ...",
        referenceUrl: "https://www.washingtontimes.com/news/2023/jun/29/book-review-loyalty/"
    },
    {
        id: 6,
        imageUrl: "assets/images/alexandra_interview.png",
        badge: "Interview",
        date: "May 12, 2021",
        title: "Interview with Author Alexandra Franzen",
        description: "Alexandra Franzen is a writer, consultant, and entrepreneur based in Hawaii. Her sixth book is The Checklist Book: Set Realistic Goals, Celebrate Tiny ...",
        referenceUrl: "https://medium.com/got-words/interview-with-author-alexandra-franzen-abd5275f541c"
    }
]

export default Blogs;