import React from 'react';
import Header from './components/header';
import Navebar from './components/navbar';
import MainBanner from './components/main-banner';
import About from './components/about';
import Services from './components/services';
import Portfolio from './components/portfolio';
import Blog from './components/blog';
import Contact from './components/contact';
import Footer from './components/footer';
import FreeQuote from './components/free-quote';
import AuthorReview from './components/modals/author-review';
import MediaPartner from './components/media-partners';
import PrivacyModal from './components/modals/privacy-modal';
import TermsConditions from './components/modals/terms-and-conditions';
import ToastMessage from './components/modals/toast';

function App() {
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = React.useState(false);
  const [isTermsConditionsModalOpen, setIsTermsConditionsModalOpen] = React.useState(false);
  const [isAuthorReviewModalOpen, setIsAuthorReviewModalOpen] = React.useState(false);
  const [openedReview, setOpenedReview] = React.useState(null);
  const [showToast, setShowToast] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);

  const toggleShowToast = () => {
    setShowToast(false);
  }

  const toggleToast = (toast: boolean, success: boolean) => {
    setShowToast(toast);
    setShowSuccess(success);
  }

  const onCloseAutherReviewModal = () => {
    setIsAuthorReviewModalOpen(false);
    setOpenedReview(null);
  };

  const onPrivacyModalClose = () => {
    setIsPrivacyModalOpen(false);
  };

  const onPrivacyClicked = () => {
    setIsPrivacyModalOpen(true);
  };

  const onTermsConditionsModalClose = () => {
    setIsTermsConditionsModalOpen(false);
  };

  const onTermsConditionsClicked = () => {
    setIsTermsConditionsModalOpen(true);
  };

  const onReadMoreClick = (review: any) => {
    setIsAuthorReviewModalOpen(true);
    setOpenedReview(review);
  }
  return (
    <>
      <Header/>
      <Navebar/>
      <MainBanner/>
      <About/>
      <Services/>
      <FreeQuote onToastShow={toggleToast}/>
      <Portfolio onReadMoreClick={onReadMoreClick}/>
      <MediaPartner/>
      <Blog/>
      <Contact onToastShow={toggleToast}/>
      <Footer onPrivacyClick={onPrivacyClicked} onTermsConditionsClick={onTermsConditionsClicked}/>
      <AuthorReview
        closeModal={onCloseAutherReviewModal}
        modalIsOpen={isAuthorReviewModalOpen}
        reviewDetails={openedReview}/>
      <PrivacyModal modalIsOpen={isPrivacyModalOpen} onModalClose={onPrivacyModalClose} />
      <TermsConditions modalIsOpen={isTermsConditionsModalOpen} onModalClose={onTermsConditionsModalClose} />
      <ToastMessage showSuccess={showSuccess} showToast={showToast} toggleShow={toggleShowToast} />
    </>
  );
}

export default App;
