import { Modal } from 'react-bootstrap';

 interface ToastMessageProps {
    showSuccess: boolean;
    showToast: boolean;
    toggleShow: () => void;
 }

const ToastMessage = (props: ToastMessageProps) => {
    return (
        <Modal show={props.showToast} onHide={props.toggleShow}>
        <Modal.Header closeButton>
          <Modal.Title>{props.showSuccess ? "Success" : "Error"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.showSuccess ?
                "Your email has been sent successfully" :
                "There was a problem sending the email. Please try again"
            }
        </Modal.Body>
      </Modal>
    )
}

export default ToastMessage;




