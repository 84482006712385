export const Packages = [
    {
        id: 1,
        name: "Social Media",
        tabImgUrl: "assets/images/amazon.png",
        detail: {
            name: "Social Media Marketing & Reviews",
            description: "An active online presence is not just helpful for authors - it’s essential. We have years of experience creating and maintaining lasting, memorable social media content for writers like you.",
            descriptionImgUrl: "assets/images/services-image.jpg",
            services: [
                "Social Media & Google SEO",
                "Goodreads and Amazon optimization",
                "Verified purchase reviews on Goodreads and Amazon",
                "Curated strategy for Facebook, Twitter, Pinterest and Instagram",
                "500 - 1000 estimated sales"
            ]
        }
    },
    {
        id: 2,
        name: "Print Media",
        tabImgUrl: "assets/images/news.avif",
        detail: {
            name: "Print Media Publicity",
            description: "A print media pitch is one of the best and most compelling ways to attract the media to tell an author’s story. We tailor each media pitch unique to the type of book, topic of book, geographic location, and more. We'll publish reviews, articles and Q & A sessions in - ",
            descriptionImgUrl: "assets/images/services-image-02.jpg",
            services: ["The New York Times", "The Atlantic", "The USA Today", "The Washington Post", "The New Yorker", "Granta", "The Paris Review", "Ploughshares", "1500 - 2500 estimated sales"]
        }
    },
    {
        id: 3,
        name: "Podcasts",
        tabImgUrl: "assets/images/tv.jpg",
        detail: {
            name: "Podcasts and interviews",
            description: "Podcasts & interviews have become powerful tools for building thought leadership and community. It’s not enough to focus only on the podcast, it’s important to develop a strategic plan to reach a large, interested audience, too. We'll organize interviews & podcasts with - ",
            descriptionImgUrl: "assets/images/services-image-03.jpg",
            services: ["NPR Book of the Day", "The New Yorker - Fiction", "Hey YA/ The Book Riot Podcast", "The New York Times Podcast", "Book 101 Review", "All About Books", "What to read next podcast", "1800 - 2800 estimated sales"]
        }
    },
    {
        id: 4,
        name: "Book Clubs",
        tabImgUrl: "assets/images/book.jpg",
        detail: {
            name: "Book Clubs Publicity",
            description: `Discussions take place across multiple platforms, and includes Q&A sessions and guest readings. Endorsements from such book clubs can be a sales-driving accolade for a book, and can also aid in highlighting debut authors. We'll ensure that your book is picked up as the "Book Club's Pick" for the month or month/s to come in following book clubs - `,
            descriptionImgUrl: "assets/images/services-image-04.jpg",
            services: ["Oprah's Book Club", "Reese's Book Club", "Belletrist Book Club", "Shondaland Book Club", "Read With Jenna Book Club", "The Good Morning America Book Club", "Our Shared Shelf Book Club", "Amazon Book Clubs", "1200 - 1800 estimated sales"]
        }
    },
    {
        id: 5,
        name: "Billboards",
        tabImgUrl: "assets/images/Billboard.jpg",
        detail: {
            name: "Billboards Times Square NYC",
            description: "Billboard advertising has an average return on investment of about 497%. This means, if done well, you can make about $5 for every $1 spent. To get in on the action, ThinqPr PR is now offering an exclusive Times Square Billboard advertisement for your book. This package includes a custom design for your book that will be displayed in Times Square during prime hours, and photographs and videos of the ad being displayed on following -",
            descriptionImgUrl: "assets/images/services-image.jpg",
            services: ["ABC SuperSign", "American Eagle Times Square", "BIG Outdoor", "Branded Cities", "City Outdoor USA", "Clear Channel Outdoor", "3500 - 5000 estimated sales"]
        }
    },
]