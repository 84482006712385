import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, Container } from "react-bootstrap";

interface MediaPartnerProps {
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const partners = [
  "assets/images/The-New-York-Times.png",
  "assets/images/gma.jpg",
  "assets/images/npr.jpg",
  "assets/images/WashPostStacked.jpeg",
  "assets/images/BR_thePodcast.png",
  "assets/images/the-new-yorker.gif",
  "assets/images/Granta.jpg"
]

const MediaPartner = (props: MediaPartnerProps) => {
  

  return (
    <Container>
        <div id="portfolio" className="our-portfolio section">
        <div className="container">
            <div className="row">
            <div className="col-lg-5">
                <div className="section-heading wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s">
                <h6>Our Affiliates</h6>
                <h4>Our <em>Media Partners</em></h4>
                <div className="line-dec"></div>
                </div>
            </div>
            </div>
        </div>
        <Carousel
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
        >
            {partners.map((partner, index) => {
            return (
                <Card style={{margin: "10px", borderRadius: "15px"}} key={index}>
                    <Card.Img
                        variant="top"
                        src={partner}
                        style={{
                            overflow: "hidden",
                            height: "183px",
                            borderRadius: "15px",
                        }}
                    />
                </Card>
            )
            })}
        </Carousel>
        </div>
    </Container>
  )
}

export default MediaPartner;