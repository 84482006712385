import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

interface AuthorReviewProps {
    modalIsOpen: boolean;
    closeModal: () => void;
    reviewDetails: any;
}

const AuthorReview = (props: AuthorReviewProps) => {
    return (
        <Modal show={props.modalIsOpen} onHide={props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Testimonials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col><Image src={props.reviewDetails?.authorImageUrl} rounded /></Col>
                    <Col>
                        <div>
                            <span style={{color: "#afafaf"}}>Author: </span>
                            <span>{props.reviewDetails?.author}</span>
                        </div>
                        <div>
                            <span style={{color: "#afafaf"}}>Book: </span>
                            <span>{props.reviewDetails?.bookFullName}</span>
                        </div>
                        {
                            props.reviewDetails?.publisher && (
                                <div>
                                    <span style={{color: "#afafaf"}}>Publisher: </span>
                                    <span>{props.reviewDetails?.publisher}</span>
                                </div>
                            )
                        }
                    </Col>
                </Row>
                <p>{props.reviewDetails?.shortDescription}</p>
            </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.closeModal}>
            Close
          </Button>
          <Button onClick={props.closeModal} style={{color: "#fff", backgroundColor: "#4da6e7", borderColor: "#4da6e7"}}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default AuthorReview;