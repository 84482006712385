import { useEffect, useState } from 'react';
import { Packages } from "./constants/packages";
import emailjs from "@emailjs/browser";

interface FreeQuoteProps {
  onToastShow: (showToast: boolean, success: boolean) => void;
}

const FreeQuote = (props: FreeQuoteProps) => {
  const [selectedPackage, setSelectedPackage] = useState(0);
  const [email, setEmail] = useState("");

  useEffect(() => emailjs.init("UKd9Azsol2LIqERqI"), []);

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setSelectedPackage(Number(event.target.value))
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setEmail(event.target.value);
  }

  const onSubmitClick = async (e: any) => {
    e.preventDefault();
    const pack: any = Packages.find(p => p.id === selectedPackage);
    const serviceId: string = "service_b0vf8ir";
    const templateId: string = "template_x6jtbr5";
    const message = `I am inerested in your ${pack.name} services. Please provide neccessary information`;
    if(pack && email.length) {
      try {
        // setLoading(true);
        await emailjs.send(serviceId, templateId, {
          from_email: email,
          from_name: email.split('@')[0],
          message: message,
        });
        props.onToastShow(true, true);
      } catch (error) {
        props.onToastShow(true, false);
      } finally {
        // setLoading(false);
      }
    }
  }


    return (
        <div id="free-quote" className="free-quote">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 offset-lg-4">
          <div className="section-heading  wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s">
            <h6>Want More Info about Our Services</h6>
            <h4>E-mail Us Now</h4>
            <div className="line-dec"></div>
          </div>
        </div>
        <div className="col-lg-8 offset-lg-2  wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
          <form id="search">
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <fieldset>
                  <select className="select-pack" onChange={onChange}>
                    <option style={{color: "#afafaf"}} value="" disabled selected>Select your service</option>
                    {Packages.map((val, index) => {
                      return <option
                        className="pack-option"
                        key={index}
                        value={val.id}
                        selected={val.id === selectedPackage}
                      >{val.name}</option>
                    })}
                  </select>
                </fieldset>
              </div>
              <div className="col-lg-4 col-sm-4">
                <fieldset>
                  <input
                  value={email}
                  onChange={onEmailChange}
                  type="address"
                  name="address"
                  className="email"
                  placeholder="Email Address..."
                  autoComplete="on"
                  required/>
                </fieldset>
              </div>
              <div className="col-lg-4 col-sm-4">
                <fieldset>
                  <button onClick={onSubmitClick} type="submit" className="main-button">Get More Info</button>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
    )
}

export default FreeQuote;